var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "21",
        height: "19",
        viewBox: "0 0 21 19",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M0.390381 15.2501V19.0001H4.49844L16.6145 7.94006L12.5064 4.19006L0.390381 15.2501ZM19.7914 5.04006C20.2186 4.65006 20.2186 4.02006 19.7914 3.63006L17.2279 1.29006C16.8007 0.900059 16.1105 0.900059 15.6833 1.29006L13.6786 3.12006L17.7866 6.87006L19.7914 5.04006Z",
          fill: "#606266",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }